import {useState} from "react";
import css from './TicTacToe.module.css'

function createRow(...cells: string[]): string[] {
  return cells;
}

function createBoard(...rows: string[][]): string[][] {
  return rows;
}
export const TicTacToe: React.FC = () => {
  const [nextPlayer, setNextPlayer] = useState("X");
  const [cells, setCells] = useState<string[][]>(
    createBoard(
      createRow("", "", ""),
      createRow("", "", ""),
      createRow("", "", "")
    )
  );



  function cellClicked(rowNumber: number, colNumber: number) {
    setNextPlayer(nextPlayer === "X" ? "O" : "X");
    const newCells = [...cells];
    newCells[rowNumber][colNumber] = nextPlayer;
    setCells(newCells);


  }

  const createTableRow = (rowIndex: number) => {
    return (
      <tr className={css.row}>
        {cells[rowIndex].map((cell,index) => (
          <td className={css.cell} key={"cell_"+ index}>
            <button disabled={cell !== ''} onClick={() => cellClicked(rowIndex, index)}>
              {cell}
            </button>
          </td>
        ))}
      </tr>
    );
  };
  return (
    <>
      <h1>Player {nextPlayer}: Make your move!</h1>
      <table className={css.board}>
        <tbody>
          {createTableRow(0)}
          {createTableRow(1)}
          {createTableRow(2)}
        </tbody>
      </table>
    </>
  );
};
